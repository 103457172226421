<template>

  <v-dialog
    width="750"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Meeting Variables of <span>Name of Meeting</span></span>
        <span>for <span class="font-weight-black">Board Name</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>

            <div v-for="(item, n) in variablesLabels" :key="n">
              <v-row class="d-flex align-center">
                <v-col cols="1">
                  <v-icon
                    :color="!item.active? 'important': 'secondary'"
                    :class="!item.active? 'opacity-high':''"
                  >
                    mdi-code-tags
                  </v-icon>
                </v-col>
                <v-col class="d-flex align-center">
                  <v-text-field
                    outlined
                    :label="item.name"
                    hide-details
                    dense
                  ></v-text-field>
                  <v-tooltip top v-if="!item.active">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="darkGrey"
                        size="18"
                        class="ml-3"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>
                      Explanation
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>

          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'MeetingVariables',
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    variablesLabels: [
      { name: 'Zoom Meeting Link', active: true },
      { name: 'Zoom Meeting ID', active: true },
      { name: 'Zoom Meeting Number', active: false },
      { name: 'Pledge Leader Name', active: true }
    ]
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
