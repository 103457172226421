<template>
  <v-navigation-drawer
    fixed
    temporary
    right
    width="600"
    v-model="show"
  >

    <div class="py-6 px-12">

      <v-row>
        <v-col>
          <h1 class="darkGrey--text font-weight-medium d-flex flex-column">
            <span>Meeting of</span>
            <span>Planning and Zoning Commission</span>
          </h1>
        </v-col>
      </v-row>

      <v-row class="mb-10">
        <v-col class="d-flex align-center">
            <span class="d-flex align-center">
              <v-icon color="secondary" size="24">mdi-check-circle-outline</v-icon>
              <span class="secondary--text text-uppercase text-caption font-weight-medium ml-2">In Development</span>
            </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
              <span class="font-weight-light text-body-2">
                Label example ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" class="d-flex align-center">
          <v-icon color="secondary" size="34">
            mdi-clock-outline
          </v-icon>
          <span class="secondary--text text-uppercase text-caption ml-2">
            Date and Time
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6">
          <span class="text-body-1 d-flex justify-end align-center text-right text-body-1">
            Mon, Sep 20, 2020 at 7:00 PM
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" class="d-flex align-center">
          <v-icon color="secondary" size="34">
            mdi-map-marker
          </v-icon>
          <span class="secondary--text text-uppercase text-caption ml-2">
            Location
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6">
          <span class="text-body-1 d-flex justify-end align-center text-right text-body-2">
            100 Main Street, BOSTRAP TX 18620
          </span>
        </v-col>
      </v-row>

      <v-row class="mb-8">
        <v-col cols="5" class="d-flex align-center">
          <v-icon color="secondary" size="34">
            mdi-view-compact
          </v-icon>
          <span class="secondary--text text-uppercase text-caption ml-2">
            Agenda Style
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6">
          <span class="text-body-1 d-flex justify-end align-center text-right text-body-2">
            Name of Agenda Style
          </span>
        </v-col>
      </v-row>

      <v-row class="mb-16">
        <v-col class="d-flex align-center secondaryLight justify-center rounded">
          <v-card elevation="0" color="transparent">
            <v-icon>mdi-alert</v-icon>
            <span class="font-italic ml-2 text-caption">The post date will be set automatically when the meeting is finalized</span>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <span class="d-flex align-center">
            <v-icon color="secondary" size="30">mdi-email</v-icon>
            <span class="text-uppercase darkGrey--text ml-4 font-weight-medium text-body-1">Auto-Notifications</span>
          </span>
        </v-col>
      </v-row>
      <v-row class="mb-16">
        <v-col>

          <v-row>
            <v-col class="d-flex align-center">
              <span class="text-uppercase text-caption secondary--text font-weight-medium">
                Board Members
              </span>
            </v-col>
            <v-col class="d-flex align-center">
              <span class="text-caption">
                Interval of 30 minutes
              </span>
            </v-col>
            <v-col class="d-flex align-center">
              <span class="text-uppercase text-caption">
                Agenda
              </span>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col class="d-flex align-center">
              <span class="text-uppercase text-caption secondary--text font-weight-medium">
                Staff
              </span>
            </v-col>
            <v-col class="d-flex align-center">
              <span class="text-caption">
                Interval of 3 hours
              </span>
            </v-col>
            <v-col class="d-flex align-center">
              <span class="text-uppercase text-caption">
                Agenda<br>Packet
              </span>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col class="d-flex align-center">
              <span class="text-uppercase text-caption secondary--text font-weight-medium">
                Public
              </span>
            </v-col>
            <v-col class="d-flex align-center">
              <span class="text-caption">
                Interval of 30 minutes
              </span>
            </v-col>
            <v-col class="d-flex align-center">
              <span class="text-uppercase text-caption">
                Agenda<br>Packet
              </span>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col class="d-flex align-center">
              <span class="text-uppercase text-caption secondary--text font-weight-medium">
                Media
              </span>
            </v-col>
            <v-col class="d-flex align-center">
              <span class="text-caption">
                Immediately
              </span>
            </v-col>
            <v-col class="d-flex align-center">
              <span class="text-uppercase text-caption">
                <span class="lightGrey--text">No link</span>
              </span>
            </v-col>
          </v-row>

        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <span class="d-flex align-center">
            <v-icon color="secondary" size="30">mdi-share-variant</v-icon>
            <span class="text-uppercase darkGrey--text ml-4 font-weight-medium text-body-1">Social Media</span>
          </span>
        </v-col>
      </v-row>
      <v-row class="mb-16">
        <v-col>

          <v-row class="py-2">
            <v-col cols="2" class="d-flex align-center">
              <v-img max-width="50" height="30" contain src="@/assets/social-facebook.svg"></v-img>
            </v-col>
            <v-col cols="5" class="d-flex align-center">
              <span class="text-caption">
                3 hours after finalizing
              </span>
            </v-col>
            <v-col cols="4" class="d-flex align-center">
              <span class="text-caption text-uppercase">
                Agenda<br>Packet
              </span>
            </v-col>
            <v-col class="d-flex align-center justify-end" cols="1">
                  <span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="expand1 = !expand1"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon size="20">mdi-message-text-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        Posted text
                      </span>
                    </v-tooltip>
                  </span>
            </v-col>
          </v-row>
          <v-expand-transition>
            <v-row v-show="expand1">
              <v-col class="veryLightGrey mb-6 pa-6 custom-view-list">
                <span class="d-flex darkGrey--text flex-column">
                  <span class="font-weight-bold text-caption mb-3">POSTED TEXT</span>
                  <span class="text-caption">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
                  </span>
                </span>
              </v-col>
            </v-row>
          </v-expand-transition>

          <v-row>
            <v-divider class="custom-divider"></v-divider>
          </v-row>

          <v-row class="py-2">
            <v-col cols="2" class="d-flex align-center">
              <v-img max-width="50" height="30" contain src="@/assets/social-twitter.svg"></v-img>
            </v-col>
            <v-col cols="5" class="d-flex align-center">
              <span class="text-caption">
                Immediately
              </span>
            </v-col>
            <v-col cols="4" class="d-flex align-center">
              <span class="text-caption text-uppercase">
                Packet
              </span>
            </v-col>
            <v-col cols="1">
              &nbsp;
            </v-col>
          </v-row>

          <v-row>
            <v-divider class="custom-divider"></v-divider>
          </v-row>

          <v-row class="py-2">
            <v-col class="d-flex" cols="7">
              <v-img max-width="50" height="30" contain src="@/assets/social-reddit-off.svg"></v-img>
              <v-img max-width="50" height="30" contain src="@/assets/social-instagram-off.svg"></v-img>
              <v-img max-width="50" height="30" contain src="@/assets/social-nextdoor-off.svg"></v-img>
            </v-col>
            <v-col class="d-flex align-center" cols="5">
              <span class="lightGrey--text text-uppercase text-caption">
                Not used for this meeting
              </span>
            </v-col>
          </v-row>

        </v-col>
      </v-row>

      <v-row class="mb-16 d-flex flex-column">
        <v-col>
          <v-row>
            <v-col class="d-flex align-center">
              <span class="d-flex align-center">
                <v-icon color="secondary" size="30">mdi-timeline-text-outline</v-icon>
                <span class="text-uppercase darkGrey--text ml-4 font-weight-medium text-body-1">Activities</span>
              </span>
              <v-switch
                inset
                color="secondary"
                class="mt-0 pa-0 ml-8"
                hide-details
                v-model="showActivities"
                dense
              ></v-switch>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="showActivities">

          <v-timeline
            align-top
            dense
          >
            <v-timeline-item
              fill-dot
              color="secondary"
              icon="mdi-share"
            >
              <span class="d-flex flex-column">
                <span class="d-flex flex-column">
                  <span>
                    <span class="font-weight-bold">Shared</span> by <span class="font-weight-bold">Charles Simon</span>
                  </span>
                  <span class="text-caption">on Tue, Dec 18, 2018 5:21 AM</span>
                </span>
                <span class="text-caption">
                  The item was shared with Noa Bender with the properties view
                </span>
              </span>
            </v-timeline-item>
            <v-timeline-item
              fill-dot
              color="secondary"
              icon="mdi-pencil"
            >
              <span class="d-flex flex-column">
                <span class="d-flex flex-column">
                  <span>
                    <span class="font-weight-bold">Edited</span> by <span class="font-weight-bold">Charles Simon</span>
                  </span>
                  <span class="text-caption">on Tue, Dec 18, 2018 5:21 AM</span>
                </span>
                <span class="text-caption">
                  The item title has been changed from:<br>
                  This is the title before.<br>
                  To:<br>
                  This is the title after it was revised.
                </span>
              </span>
            </v-timeline-item>
            <v-timeline-item
              fill-dot
              color="primary"
              icon="mdi-file-outline"
            >
              <span class="d-flex flex-column">
                <span class="d-flex flex-column">
                  <span>
                    <span class="font-weight-bold">Created</span> by <span class="font-weight-bold">Charles Simon</span>
                  </span>
                  <span class="text-caption">on Tue, Dec 18, 2018 5:21 AM</span>
                </span>
                <span class="text-caption">
                  Meeting was created and placed in the Thursday, July 18, 2019 7:00 PM - Parks and Recreation Commission - Regular Meeting
                </span>
              </span>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>

      <v-btn depressed @click="show=false">
        Close
      </v-btn>

    </div>

  </v-navigation-drawer>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'MeetingView',
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      expand1: false,
      showActivities: true
    }
  },
  methods: {
    close () {
      this.$emit('closed')
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
