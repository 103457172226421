<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Auto-notifications of item <span class="font-weight-black">Item Name</span></span>
        <span>for <span class="font-weight-black">Board Name</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <template>
          <v-row>
            <v-col>

              <v-row>
                <v-col class="pb-0 secondary--text text-uppercase font-weight-medium">
                  Board Members
                </v-col>
              </v-row>

              <v-row>
                <v-col class="d-flex align-center" cols="7">
                  <span class="d-flex flex-row align-center">
                    <p class="ma-0 mr-6">Include links to</p>
                    <v-switch
                      inset
                      label="Agenda"
                      color="secondary"
                      class="mt-0 pa-0 mr-4"
                      hide-details
                    ></v-switch>
                    <v-switch
                      inset
                      label="Packet"
                      color="secondary"
                      class="mt-0 pa-0 mr-4"
                      hide-details
                    ></v-switch>
                  </span>
                </v-col>
                <v-col class="d-flex align-center" cols="5">
                  <v-select
                    :items="timeIntervals"
                    item-text="text"
                    item-value="value"
                    label="Interval"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-divider class="custom-divider"></v-divider>
          </v-row>

          <v-row>
            <v-col>
              <v-row>
                <v-col class="pb-0 secondary--text text-uppercase font-weight-medium">
                  Staff
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex align-center" cols="7">
                  <span class="d-flex flex-row align-center">
                    <p class="ma-0 mr-6">Include links to</p>
                    <v-switch
                      inset
                      label="Agenda"
                      color="secondary"
                      class="mt-0 pa-0 mr-4"
                      hide-details
                    ></v-switch>
                    <v-switch
                      inset
                      label="Packet"
                      color="secondary"
                      class="mt-0 pa-0 mr-4"
                      hide-details
                    ></v-switch>
                  </span>
                </v-col>
                <v-col class="d-flex align-center" cols="5">
                  <v-select
                    :items="timeIntervals"
                    item-text="text"
                    item-value="value"
                    label="Interval"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-divider class="custom-divider"></v-divider>
          </v-row>

          <v-row>
            <v-col>
              <v-row>
                <v-col class="pb-0 secondary--text text-uppercase font-weight-medium">
                  Public
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex align-center" cols="7">
                  <span class="d-flex flex-row align-center">
                    <p class="ma-0 mr-6">Include links to</p>
                    <v-switch
                      inset
                      label="Agenda"
                      color="secondary"
                      class="mt-0 pa-0 mr-4"
                      hide-details
                    ></v-switch>
                    <v-switch
                      inset
                      label="Packet"
                      color="secondary"
                      class="mt-0 pa-0 mr-4"
                      hide-details
                    ></v-switch>
                  </span>
                </v-col>
                <v-col class="d-flex align-center" cols="5">
                  <v-select
                    :items="timeIntervals"
                    item-text="text"
                    item-value="value"
                    label="Interval"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </template>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'MeetingAutoNotifications',
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    timeIntervals: [
      'No Notification',
      'Immediately',
      '30 min',
      '1 hour',
      '3 hours'
    ]
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
