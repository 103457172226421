<template>

  <v-dialog
    width="1100"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-tabs
        v-model="tab"
        color="transparent"
        grow class="d-none"
      >
      </v-tabs>

      <v-tabs-items v-model="tab" class="transparent">

        <v-tab-item key="0">

          <v-card-title class="mb-12" style="height: 70px;">

            <v-row>
              <v-col>
                <span v-if="builderType === 'agenda'">Regions of <span class="font-weight-black">Name of the Agenda Style</span></span>
                <span v-if="builderType === 'meeting'">Edit Meeting Draft of <span class="font-weight-black">Meeting Name</span> for <span class="font-weight-black">Name of Board</span></span>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="darkGrey"
                      size="18"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    Explanation for this area
                  </span>
                </v-tooltip>
              </v-col>
              <v-col class="d-flex justify-end align-center" v-if="builderType === 'agenda'">
                <v-switch
                  inset
                  label="Preview"
                  color="secondary"
                  v-model="previewRegions"
                  class="mt-0"
                  hide-details
                ></v-switch>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      height="40"
                      color="orange lighten-2"
                      class="white--text ml-8"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">mdi-plus</v-icon> Add Region
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="addRegion">
                      <v-list-item-title>Duplicate last region</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="addRegion">
                      <v-list-item-title>Empty</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>

          </v-card-title>

          <v-card-text class="pa-3 mb-6 overflow-auto">

            <div class="letter-wrapper">
              <v-card
                elevation="8"
                class="d-flex flex-column rounded-0 letter-paper"
                style="padding: 0.5in"
              >

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" style="position: relative">
                      <span class="d-flex row-editor">
                        <div class="d-flex" style="position: relative">
                          <div
                            class="edit-cell d-flex align-center"
                            :class="{ 'table-preview' : previewRegions }"
                            style="width: 3.75in"
                          >
                            Test 1
                          </div>
                          <div
                            class="edit-cell d-flex align-center"
                            :class="{ 'table-preview' : previewRegions }"
                            style="width: 2.0in"
                          >
                            Test 2
                          </div>
                          <div
                            class="edit-cell d-flex align-center"
                            :class="{ 'table-preview' : previewRegions }"
                            style="width: 1.75in"
                          >
                            Test 3
                          </div>
                        </div>
                      </span>
                      <span class="d-flex row-editor">
                        <div class="d-flex" style="position: relative">
                          <div
                            class="edit-cell d-flex align-center"
                            :class="{ 'table-preview' : previewRegions }"
                            style="width: 7.5in; border-bottom: 1px solid black !important;"
                          >
                            Test 4
                          </div>
                        </div>
                      </span>
                      <span class="d-flex row-editor">
                          <div class="d-flex" style="position: relative">
                            <div
                              class="edit-cell d-flex align-center"
                              :class="{ 'table-preview' : previewRegions }"
                              style="width: 1.5in"
                            >
                              Test 5
                            </div>
                            <div
                              class="edit-cell d-flex align-center"
                              :class="{ 'table-preview' : previewRegions }"
                              style="width: 1.5in"
                            >
                              Test 6
                            </div>
                            <div
                              class="edit-cell d-flex align-center"
                              :class="{ 'table-preview' : previewRegions }"
                              style="width: 1.5in"
                            >
                              Test 7
                            </div>
                            <div
                              class="edit-cell d-flex align-center"
                              :class="{ 'table-preview' : previewRegions }"
                              style="width: 3.0in"
                            >
                              Test 8
                            </div>
                          </div>
                        </span>
                      <span class="edit-column">
                        <v-menu
                          offset-x
                          open-on-hover
                          min-width="30"
                          :close-on-content-click="false"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              color="transparent"
                              tile
                              block
                              @click="goToRegion"
                              height="100%"
                            >
                              <v-icon>mdi-vertical-dots</v-icon>
                            </v-btn>
                          </template>
                          <v-list
                            dense
                          >
                            <v-list-item class="text-uppercase text-caption">
                              {{ regionName }}
                            </v-list-item>
                            <v-list-item class="d-flex justify-start align-start">
                              <v-tooltip bottom v-if="builderType==='agenda'">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="secondary">mdi-table-arrow-up</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Insert region before
                                </span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="goToRegion"
                                    @mouseenter="addRegionMenu=false"
                                  >
                                    <v-icon>mdi-pencil</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Edit <span class="text-uppercase font-weight-bold">{{ regionName }}</span> region
                                </span>
                              </v-tooltip>
                              <v-tooltip top v-if="builderType==='meeting'">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="secondary"
                                    icon
                                    v-on="on"
                                    v-bind="attrs"
                                    v-model="addRegion"
                                    @mouseenter="addRegionMenu=true"
                                  >
                                    <v-icon color="secondary">mdi-table-row-plus-after</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Insert another region below <span class="text-uppercase font-weight-bold">{{ regionName }}</span>
                                </span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="builderType==='agenda'">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-chevron-up</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Move region up
                                </span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="builderType==='agenda'">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Move region down
                                </span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="builderType==='meeting'">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @mouseenter="addRegionMenu=false"
                                  >
                                    <v-icon>mdi-format-page-break</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                        Insert page break below <span class="text-uppercase font-weight-bold">{{ regionName }}</span>
                                      </span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="builderType==='meeting'">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @mouseenter="addRegionMenu=false"
                                  >
                                    <v-icon>mdi-rotate-left</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Restore <span class="text-uppercase font-weight-bold">{{ regionName }}</span> to original
                                </span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-speed-dial
                                    v-model="fab"
                                  >
                                    <template v-slot:activator>
                                      <v-btn
                                        v-model="fab"
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @mouseenter="addRegionMenu=false"
                                      >
                                        <v-icon color="important">
                                          mdi-delete
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <v-btn
                                      fab
                                      dark
                                      small
                                      color="grey"
                                    >
                                      NO
                                    </v-btn>
                                    <v-btn
                                      fab
                                      dark
                                      small
                                      color="important"
                                    >
                                      YES
                                    </v-btn>
                                  </v-speed-dial>
                                </template>
                                <span>
                                  Delete <span class="text-uppercase font-weight-bold">{{ regionName }}</span> region
                                </span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="builderType==='agenda'">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="secondary">mdi-table-arrow-down</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  Insert region after
                                </span>
                              </v-tooltip>
                            </v-list-item>
                            <v-list-item v-if="addRegionMenu">
                              <v-card elevation="0" max-height="300" width="100%" style="overflow-y: auto;">
                                <v-list>
                                  <v-list-item v-for="(item, i) in testRegions" :key="i" @click="testAddRegion">
                                    <v-list-item-title>{{ item }}</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-card>
                            </v-list-item>
                            <v-list-item class="pa-7 py-0 pb-4" v-if="addRegionMenu" @click="testAddRegion">
                              <v-list-item-title class="grey--text">
                                <v-icon color="grey">mdi-border-none-variant</v-icon> Blank region
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </span>
                    </span>
                  </template>
                  <span>Name of Region</span>
                </v-tooltip>

              </v-card>
            </div>

          </v-card-text>

          <v-card-actions class="d-flex align-center justify-center">
            <v-spacer></v-spacer>
            <v-btn depressed width="130" @click="show=false">Ok</v-btn>
          </v-card-actions>

        </v-tab-item>

        <v-tab-item key="1">

          <v-card-title class="mb-12" style="height: 70px;">

            <v-row>
              <v-col>
                <v-btn
                  depressed
                  icon
                  @click="goToAllRegions"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span>Regions</span>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="darkGrey"
                      size="18"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    Explanation for this area
                  </span>
                </v-tooltip>
              </v-col>
              <v-col class="d-flex justify-end align-center" v-if="tabRegion > 0">
                <v-switch
                  inset
                  label="Preview"
                  color="secondary"
                  v-model="previewRegion"
                  class="mt-0"
                  hide-details
                ></v-switch>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      height="40"
                      color="orange lighten-2"
                      class="white--text ml-8"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">mdi-plus</v-icon> Add Row
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="addRow">
                      <v-list-item-title>Duplicate last row</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="addRow">
                      <v-list-item-title>Empty</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>

          </v-card-title>

          <v-card-text style="min-height: 450px;">

            <v-tabs
              v-model="tabRegion"
              centered
              class="custom-tab mb-10"
              color="secondary"
            >
              <v-spacer></v-spacer>
              <v-tab class="secondary white--text">
                <v-icon>mdi-cog</v-icon>
              </v-tab>
              <v-spacer></v-spacer>
              <v-tab>Begin Block</v-tab>
              <v-tab>End Block</v-tab>
              <v-tab>Item Container</v-tab>
              <v-tab>No Item Text</v-tab>
              <v-spacer></v-spacer>
            </v-tabs>

            <v-tabs-items v-model="tabRegion">
              <v-tab-item :key="0">
                <v-card flat>

                  <v-card-text>

                    <v-row>
                      <v-col cols="5" class="pr-8">
                        <v-row>
                          <v-col>
                            <v-text-field
                              outlined
                              label="Name"
                              hide-details
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-switch
                              inset
                              label="Can contain items"
                              hide-details
                              color="secondary"
                            ></v-switch>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-switch
                              inset
                              label="Include in minutes"
                              hide-details
                              color="secondary"
                            ></v-switch>
                          </v-col>
                        </v-row>
                        <v-row v-if="builderType === 'agenda'">
                          <v-col>
                            <v-switch
                              inset
                              label="Include in agenda by default"
                              hide-details
                              color="secondary"
                            ></v-switch>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-switch
                              inset
                              label="Reset Numbering for each numbering group"
                              hide-details
                              color="secondary"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col class="pa-0">
                        <v-row>
                          <v-col
                            cols="7"
                            class="pr-8"
                          >
                            <v-row>
                              <v-col>
                                <v-select
                                  label="Region Numbering"
                                  outlined
                                  small
                                  hide-details
                                  v-model="numberingSelected[0].selected"
                                  :items="numberingFilter"
                                  item-value="sample"
                                  item-text="type"
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-select
                                  label="Great Numbering"
                                  outlined
                                  hide-details
                                  v-model="numberingSelected[1].selected"
                                  :items="numberingType"
                                  item-value="sample"
                                  item-text="type"
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-select
                                  label="Grant Numbering"
                                  outlined
                                  hide-details
                                  v-model="numberingSelected[2].selected"
                                  :items="numberingType"
                                  item-value="sample"
                                  item-text="type"
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-select
                                  label="Parent Numbering"
                                  outlined
                                  hide-details
                                  v-model="numberingSelected[3].selected"
                                  :items="numberingType"
                                  item-value="sample"
                                  item-text="type"
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-select
                                  label="First Numbering"
                                  outlined
                                  hide-details
                                  v-model="numberingSelected[4].selected"
                                  :items="numberingType"
                                  item-value="sample"
                                  item-text="type"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col
                            cols="5"
                            class="grey lighten-3 mt-6 mb-6 pa-8"
                          >
                              <span class="text-uppercase font-weight-bold d-flex mb-6">
                                Numbering Sample
                              </span>
                            <span class="d-flex mb-3">
                                <span v-text="numberingSelected[0].symbol" class="mr-2"></span>Region
                              </span>
                            <span class="d-flex mb-3 ml-2">
                                <span v-text="numberingSelected[1].symbol" class="mr-2"></span> Great
                              </span>
                            <span class="d-flex mb-3 ml-4">
                                <span v-text="numberingSelected[2].symbol" class="mr-2"></span> Grant
                              </span>
                            <span class="d-flex mb-3 ml-6">
                                <span v-text="numberingSelected[3].symbol" class="mr-2"></span> Parent
                              </span>
                            <span class="d-flex mb-3 ml-8">
                                <span v-text="numberingSelected[4].symbol" class="mr-2"></span> First
                              </span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                  </v-card-text>

                </v-card>
              </v-tab-item>
              <v-tab-item :key="1">
                <v-card flat>

                  <v-card-text class="pa-3 mb-6 overflow-auto">

                    <div class="letter-wrapper">
                      <v-card
                        elevation="8"
                        class="d-flex flex-column rounded-0 letter-width"
                        style="padding: 0.5in"
                      >

                        <span class="d-flex row-editor">
                          <div class="d-flex" style="position: relative">
                            <div
                              class="edit-cell d-flex align-center"
                              :class="{ 'table-preview' : previewRegion }"
                              style="width: 3.75in"
                            >
                              Test 1
                            </div>
                            <div
                              class="edit-cell d-flex align-center"
                              :class="{ 'table-preview' : previewRegion }"
                              style="width: 2.0in"
                            >
                              Test 2
                            </div>
                            <div
                              class="edit-cell d-flex align-center"
                              :class="{ 'table-preview' : previewRegion }"
                              style="width: 1.75in"
                            >
                              Test 3
                            </div>
                            <span class="edit-column">
                              <v-menu
                                offset-x
                                open-on-hover
                                min-width="30"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="transparent"
                                    tile
                                    block
                                    @click="goToRow"
                                  >
                                    <v-icon>mdi-vertical-dots</v-icon>
                                  </v-btn>
                                </template>
                                <v-list
                                  dense
                                >
                                  <v-list-item class="d-flex justify-center">
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon color="secondary">mdi-table-row-plus-before</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Insert row before
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="goToRow"
                                        >
                                          <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Edit row
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon>mdi-chevron-up</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Move row up
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon>mdi-chevron-down</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Move row down
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon color="important">mdi-delete</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Delete row
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon color="secondary">mdi-table-row-plus-after</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Insert row after
                                      </span>
                                    </v-tooltip>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </span>
                          </div>
                        </span>
                        <span class="d-flex row-editor">
                          <div class="d-flex" style="position: relative">
                            <div
                              class="edit-cell d-flex align-center"
                              :class="{ 'table-preview' : previewRegion }"
                              style="width: 7.5in; border-bottom: 1px solid black !important;"
                            >
                              Test 4
                            </div>
                            <span class="edit-column">
                              <v-menu
                                offset-x
                                open-on-hover
                                min-width="30"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="transparent"
                                    tile
                                    block
                                    @click="goToRow"
                                  >
                                    <v-icon>mdi-vertical-dots</v-icon>
                                  </v-btn>
                                </template>
                                <v-list
                                  dense
                                >
                                  <v-list-item class="d-flex justify-center">
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon color="secondary">mdi-table-row-plus-before</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Insert row before
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="goToRow"
                                        >
                                          <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Edit row
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon>mdi-chevron-up</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Move row up
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon>mdi-chevron-down</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Move row down
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon color="important">mdi-delete</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Delete row
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon color="secondary">mdi-table-row-plus-after</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Insert row after
                                      </span>
                                    </v-tooltip>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </span>
                          </div>
                        </span>
                        <span class="d-flex row-editor">
                          <div class="d-flex" style="position: relative">
                            <div
                              class="edit-cell d-flex align-center"
                              :class="{ 'table-preview' : previewRegion }"
                              style="width: 1.5in"
                            >
                              Test 5
                            </div>
                            <div
                              class="edit-cell d-flex align-center"
                              :class="{ 'table-preview' : previewRegion }"
                              style="width: 1.5in"
                            >
                              Test 6
                            </div>
                            <div
                              class="edit-cell d-flex align-center"
                              :class="{ 'table-preview' : previewRegion }"
                              style="width: 1.5in"
                            >
                              Test 7
                            </div>
                            <div
                              class="edit-cell d-flex align-center"
                              :class="{ 'table-preview' : previewRegion }"
                              style="width: 3.0in"
                            >
                              Test 8
                            </div>
                            <span class="edit-column">
                              <v-menu
                                offset-x
                                open-on-hover
                                min-width="30"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="transparent"
                                    tile
                                    block
                                    @click="goToRow"
                                  >
                                    <v-icon>mdi-vertical-dots</v-icon>
                                  </v-btn>
                                </template>
                                <v-list
                                  dense
                                >
                                  <v-list-item class="d-flex justify-center">
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon color="secondary">mdi-table-row-plus-before</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Insert row before
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="goToRow"
                                        >
                                          <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Edit row
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon>mdi-chevron-up</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Move row up
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon>mdi-chevron-down</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Move row down
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon color="important">mdi-delete</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Delete row
                                      </span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon color="secondary">mdi-table-row-plus-after</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Insert row after
                                      </span>
                                    </v-tooltip>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </span>
                          </div>
                        </span>

                      </v-card>
                    </div>

                  </v-card-text>

                </v-card>
              </v-tab-item>
              <v-tab-item :key="2">
                <v-card flat>
                  <v-card-text>

                    <v-card class="d-flex justify-center align-center lightGrey--text text-uppercase" height="280" elevation="0">
                      Add your First Row
                    </v-card>

                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item :key="3">
                <v-card flat>
                  <v-card-text>

                    <v-card class="d-flex justify-center align-center lightGrey--text text-uppercase" height="280" elevation="0">
                      Add your First Row
                    </v-card>

                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item :key="4">
                <v-card flat>
                  <v-card-text>

                    <v-card class="d-flex justify-center align-center lightGrey--text text-uppercase" height="280" elevation="0">
                      Add your First Row
                    </v-card>

                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

          </v-card-text>

          <v-card-actions class="d-flex align-center justify-center">
            <v-spacer></v-spacer>
            <v-btn depressed width="130" @click="show=false">Ok</v-btn>
          </v-card-actions>

        </v-tab-item>

        <v-tab-item key="2">

          <v-card-title class="mb-0" style="height: 70px;">

            <v-row>
              <v-col>
                <v-btn
                  depressed
                  icon
                  @click="goToRegion"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span>Row</span>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="darkGrey"
                      size="18"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    Explanation for this area
                  </span>
                </v-tooltip>
              </v-col>
              <v-col class="d-flex justify-end align-center">
                <v-switch
                  inset
                  label="Preview"
                  color="secondary"
                  v-model="previewRow"
                  class="mt-0"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>

          </v-card-title>

          <v-card-text class="mb-6" style="min-height: 450px;">

            <v-row>
              <v-col class="pb-0">
                <v-tabs
                  v-model="rowColumns"
                  centered
                  color="secondary"
                >
                  <v-tab>1</v-tab>
                  <v-tab>2</v-tab>
                  <v-tab>3</v-tab>
                  <v-tab>4</v-tab>
                </v-tabs>
              </v-col>
            </v-row>

            <v-row class="d-flex mb-8">
              <v-col
                class="veryLightGrey pa-8"
              >

                <v-row>
                  <v-col class="py-0">
                    <v-textarea
                      height="80"
                      class="white mt-0 pt-0 rounded-0"
                      hide-details
                      outlined
                      no-resize
                    ></v-textarea>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>

            <v-row>
              <v-col
              >

                <div class="letter-wrapper">
                  <v-card
                    elevation="8"
                    class="d-flex flex-column rounded-0 letter-width"
                    style="padding: 0.5in"
                  >

                    <span class="d-flex row-editor mb-6">

                      <div class="d-flex">
                        <div
                          class="edit-cell d-flex align-center"
                          v-for="(item,key) in rowContent"
                          ref="rowCol"
                          :key="'col' + key"
                          @click="selectColumn(key)"
                          :class="{ 'table-preview' : previewRow }"
                        >
                          {{ item }}
                          <span class="edit-column">
                            <v-menu
                              offset-y
                              open-on-hover
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  color="transparent"
                                  tile
                                  block
                                  @click="selectColumn(key)"
                                >
                                  <v-icon>mdi-vertical-dots</v-icon>
                                </v-btn>
                              </template>
                              <v-list
                                dense
                              >
                                <v-list-item class="d-flex justify-center">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon color="secondary">mdi-table-column-plus-before</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>
                                      Insert column before
                                    </span>
                                  </v-tooltip>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="selectColumn(key)"
                                      >
                                        <v-icon>mdi-pencil</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>
                                      Edit column
                                    </span>
                                  </v-tooltip>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon color="important">mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>
                                      Delete column
                                    </span>
                                  </v-tooltip>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon color="secondary">mdi-table-column-plus-after</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>
                                      Insert column after
                                    </span>
                                  </v-tooltip>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </span>
                        </div>
                      </div>

                    </span>

                    <vue-slider
                      v-model="columnDots"
                      v-bind="sliderOptions"
                      class="custom-slider"
                      @change="updateRowColumns"
                      v-if="!previewRow"
                      :enable-cross="false"
                    ></vue-slider>

                  </v-card>
                </div>

              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions class="d-flex align-center justify-center">
            <v-spacer></v-spacer>
            <v-btn depressed width="130" @click="goToRegion">Cancel</v-btn>
            <v-btn depressed width="130" color="secondary">Update</v-btn>
          </v-card-actions>

        </v-tab-item>

      </v-tabs-items>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
export default Vue.extend({
  name: 'AgendaStyleRegions',
  components: {
    VueSlider
  },
  props: {
    value: {
      required: true,
      type: Boolean
    },
    builderType: {
      required: true,
      type: String
    }
  },
  data: () => ({
    tab: 0,
    fab: false,
    addRegionMenu: false,
    tabRegion: 0,
    rowColumns: 0,
    regionName: 'Region Sample Name',
    testRegions: [
      'Region 1',
      'Region 2',
      'Region 3',
      'Region 4',
      'Region 5',
      'Region 6',
      'Region 7',
      'Region 8',
      'Region 9',
      'Region 10',
      'Region 11',
      'Region 12',
      'Region 13',
      'Region 14'
    ],
    rowContent: [
      '',
      '',
      '',
      ''],
    columnDots: [0, 1.5, 1.75, 4.5, 7.5],
    sliderOptions: {
      process: false,
      tooltipPlacement: 'bottom',
      adsorb: true,
      clickable: false,
      interval: 0.250,
      max: 7.5,
      min: 0,
      minRange: 0.250
    },
    previewRegions: false,
    previewRegion: false,
    previewRow: false,
    rowSample: [
      { text: 'Name' },
      { text: 'Administrator' },
      { text: 'Email' },
      { text: 'Workgroups' },
      { text: 'Actions' }
    ],
    numberingType: [
      { type: 'Decimal', sample: '1.' },
      { type: 'Capital Alphabet', sample: 'A.' },
      { type: 'Lowercase Alphabet', sample: 'a.' },
      { type: 'Capital Roman', sample: 'I.' },
      { type: 'Lowercase Roman', sample: 'i.' },
      { type: 'Circle', sample: '●' },
      { type: 'Square', sample: '■' },
      { type: 'Disc', sample: '○' },
      { type: 'Diamond', sample: '◆' },
      { type: 'Same as previous region', sample: 'previous', exclude: true }
    ],
    numberingSelected: [
      { selected: '1.', symbol: '1.' },
      { selected: 'A.', symbol: 'A.' },
      { selected: 'a.', symbol: 'a.' },
      { selected: 'I.', symbol: 'I.' },
      { selected: '●', symbol: '●' }
    ]
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    numberingFilter () {
      return this.numberingType.filter(item => item.exclude === undefined)
    }
  },
  methods: {
    goToAllRegions () {
      this.tab = 0
    },
    goToRegion () {
      this.tab = 1
    },
    goToRow () {
      this.tab = 2
      setTimeout(this.updateRowColumns, 200)
    },
    updateRowColumns () {
      const totalCols = this.columnDots.length
      for (let i = 0; i < totalCols; i++) {
        if (i < (totalCols - 1)) {
          const columnWidth = (this.columnDots[i + 1] - this.columnDots[i]) + 'in'
          this.$refs.rowCol[i].style.width = columnWidth
        } else {
          const columnWidth = (this.sliderOptions.max - this.columnDots[i]) + 'in'
          if ((this.sliderOptions.max - this.columnDots[i]) >= this.sliderOptions.interval) {
            this.$refs.rowCol[i].style.width = columnWidth
          }
        }
      }
    },
    selectColumn (key) {
      this.rowColumns = key
    },
    deleteRegion () {
      console.log('delete agenda style region')
    },
    deleteRow () {
      console.log('delete agenda style row')
    },
    addRow () {
      console.log('add new agenda style row')
    },
    addRegion () {
      console.log('add new agenda style region')
    },
    testAddRegion () {
      console.log(1)
    }
  },
  watch: {
    numberingSelected: {
      handler () {
        for (let i = 0; i < 5; i++) {
          if (this.numberingSelected[i].selected === 'previous') {
            this.numberingSelected[i].symbol = this.numberingSelected[i - 1].symbol
          } else {
            this.numberingSelected[i].symbol = this.numberingSelected[i].selected
          }
        }
      },
      deep: true
    }
  },
  mounted () {
    console.log('mounted')
    this.builderType === 'agenda' ? this.previewRegions = false : this.previewRegions = true
  }
})
</script>
