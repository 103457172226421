<template>

  <v-dialog
    width="750"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <span v-if="success">
        <v-row>
          <v-col class="d-flex justify-center">
            <v-icon color="success" size="75">mdi-check-circle</v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center headline flex-column align-center">
            <span><span class="font-weight-black">Name of Meeting</span> of</span>
            <span><span class="font-weight-black">Name of the Board</span> successfuly finalized.</span>
          </v-col>
        </v-row>
      </span>

      <span v-if="fail">
        <v-row>
          <v-col class="d-flex justify-center">
            <v-icon color="important" size="75">mdi-alert-circle</v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center headline flex-column align-center">
            <span><span class="font-weight-black">Name of Meeting</span> of</span>
            <span><span class="font-weight-black">Name of the Board</span> did not finalize successfully.</span>
          </v-col>
        </v-row>
      </span>

      <span>

        <v-card-title
          v-if="!success && !fail"
          class="d-flex justify-center align-start headline flex-column mb-6"
        >
          <span>Finalizing <span class="font-weight-black">Name of Meeting</span></span>
          <span>of <span class="font-weight-black">Name of the Board</span>...</span>
        </v-card-title>

        <span class="modal-close-btn">
          <v-btn
            icon
            color="grey"
            @click="finalizeMeeting"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </span>

        <v-card-text
          v-if="!success && !fail || viewDetail"
          class="d-flex justify-center flex-column"
        >

          <v-expansion-panels flat accordion class="custom-panel-separator">

            <v-expansion-panel
              v-for="(item,n) in progress"
              :key="n"
            >
              <v-expansion-panel-header class="pa-0" :disabled="!item.failed">
                <v-row>
                  <v-col cols="9" class="d-flex align-center">
                    <span class="text-uppercase">
                      {{ item.label }}
                    </span>
                  </v-col>
                  <v-col cols="3">
                    <v-row>

                      <v-col cols="3">
                        <v-icon color="success" class="mr-2" v-if="item.done">mdi-check</v-icon>
                        <v-icon color="important" class="mr-2" v-if="item.failed">mdi-alert-circle</v-icon>
                        <v-progress-circular
                          indeterminate
                          color="secondary"
                          size="18"
                          :width="2"
                          class="mr-2"
                          v-if="item.progress"
                        ></v-progress-circular>
                        <span v-if="item.queue">&nbsp;</span>
                      </v-col>
                      <v-col class="d-flex align-center">
                        <span class="success--text" v-if="item.done">Done</span>
                        <span class="important--text" v-if="item.failed">Failed</span>
                        <span class="secondary--text" v-if="item.progress">In progress</span>
                        <span class="lightGrey--text" v-if="item.queue">On queue</span>
                      </v-col>

                    </v-row>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0 veryLightGrey">
                <span class="d-flex flex-column pt-4 important--text text-caption">
                    {{ item.error }}
                </span>
              </v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panels>

        </v-card-text>

        <v-card-actions class="d-flex align-center justify-center mt-8 mb-6" v-if="success || fail ">
          <v-btn depressed width="130" @click="viewDetail = !viewDetail">{{ viewDetail? 'Hide' : 'View' }} details</v-btn>
          <v-btn depressed width="130" @click="show = false">Ok</v-btn>
        </v-card-actions>

        <v-card-actions class="d-flex align-center justify-center" v-if="!success && !fail">
          <v-spacer></v-spacer>
          <v-btn depressed width="130" @click="finalizeMeeting">Ok</v-btn>
        </v-card-actions>

      </span>

      <span style="position: absolute; bottom: 0; opacity: 0.4">
        <v-btn
          depressed
          small
          tile
          @click="testSuccess"
          color="success"
        >
          TEST ONLY: SUCCESS
        </v-btn>
        <v-btn
          depressed
          small
          tile
          @click="testFail"
          color="important"
        >
          TEST ONLY: FAIL
        </v-btn>
        <v-btn
          depressed
          small
          tile
          @click="testInitial"
          color="lightGrey"
        >
          TEST ONLY: INITIAL
        </v-btn>
      </span>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'MeetingFinalizeProgress',
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      success: false,
      fail: false,
      viewDetail: false,
      progress: [
        {
          label: 'Locking Meeting',
          done: true,
          progress: false,
          queue: false,
          failed: false,
          error: 'Description for error (or errors). If errors are only short messages, we can use tooltip, instead of this component.'
        },
        {
          label: 'Converting Items',
          done: false,
          progress: false,
          queue: false,
          failed: true,
          error: 'Description for error (or errors). If errors are only short messages, we can use tooltip, instead of this component.'
        },
        {
          label: 'Making Agenda',
          done: true,
          progress: false,
          queue: false,
          failed: false,
          error: 'Description for error (or errors). If errors are only short messages, we can use tooltip, instead of this component.'
        },
        {
          label: 'Assembling Packet',
          done: false,
          progress: true,
          queue: false,
          failed: false,
          error: 'Description for error (or errors). If errors are only short messages, we can use tooltip, instead of this component.'
        },
        {
          label: 'Checking Packet',
          done: false,
          progress: false,
          queue: true,
          failed: false,
          error: 'We can use tooltip, instead of this component.'
        }
      ]
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    finalizeMeeting () {
      this.show = false
      this.$emit('callFinalizeMeeting')
    },
    testSuccess () {
      this.progress[1].done = true
      this.progress[1].failed = false
      this.progress[3].done = true
      this.progress[3].progress = false
      this.progress[4].done = true
      this.progress[4].failed = false
      this.progress[4].queue = false
      this.success = true
      this.fail = false
    },
    testFail () {
      this.progress[1].done = false
      this.progress[1].failed = true
      this.progress[3].done = true
      this.progress[3].progress = false
      this.progress[4].done = false
      this.progress[4].failed = true
      this.progress[4].queue = false
      this.success = false
      this.fail = true
    },
    testInitial () {
      this.progress[1].done = false
      this.progress[1].failed = true
      this.progress[3].done = false
      this.progress[3].progress = true
      this.progress[4].done = false
      this.progress[4].failed = false
      this.progress[4].queue = true
      this.success = false
      this.fail = false
    }
  }
})
</script>
