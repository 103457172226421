<template>

  <v-dialog
    width="750"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Post Date Method of <span class="font-weight-black">Meeting Name</span></span>
        <span>for <span class="font-weight-black">Board Name</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>

            <v-row>
              <v-col>
                <v-select
                  :items="postDateMethod"
                  label="Post Date Method"
                  v-model="postDateMethodActive"
                  item-text="label"
                  outlined
                  hide-details
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="postDateMethodActive == 1">
              <v-col>
                <v-text-field
                  outlined
                  label="Post Date"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="postDateMethodActive == 2">
              <v-col>
                <v-text-field
                  outlined
                  label="Date"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col>
                <v-menu ref="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Time"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    format="ampm"
                    full-width
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row v-if="postDateMethodActive == 3">
              <v-col>
                <v-icon color="orange lighten-2">mdi-alert</v-icon>
                The post date will not be included in the agenda
              </v-col>
            </v-row>

          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'MeetingPostDateMethod',
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    postDateMethodActive: null,
    postDateMethod: [
      { value: 1, label: 'Manually enter the exact text you want to be used as the post date' },
      { value: 2, label: 'Schedule a specific date and time to use as the post date' },
      { value: 3, label: 'Omit the post date' }
    ]
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
