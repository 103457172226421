<template>

  <v-dialog
    width="1100"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-start headline mb-6">
        <span>New Meeting for <span class="font-weight-black">Board Name</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row class="d-flex">
          <v-col cols="8" v-if="labelDisplay">
            <v-text-field
              outlined
              label="Label"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="d-flex align-center py-8">
            <v-switch
              inset
              label="Label"
              color="secondary"
              hide-details
              class="pa-0 ma-0"
              v-model="labelDisplay"
            ></v-switch>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="regularGrey"
                  size="18"
                  class="ml-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span>
                Explanation
              </span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row class="d-flex">
          <v-col cols="8">
            <v-textarea
              outlined
              label="Location"
              hide-details
            ></v-textarea>
          </v-col>
          <v-col cols="4">
            <v-text-field
              outlined
              label="Date"
              hide-details
              class="mb-5 d-flex"
            ></v-text-field>
            <v-text-field
              outlined
              label="Time"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="d-flex">
          <v-col cols="8">
            <v-select
              label="Agenda Style"
              outlined
              small
              hide-details
              :items="agendaStyles"
            ></v-select>
          </v-col>
        </v-row>

        <v-row class="d-flex mb-16">
          <v-col cols="8">
            <v-select
              label="Post Date Method"
              outlined
              small
              hide-details
              item-text="label"
              :items="postDateMethods"
              v-model="postDateMethodSelected"
            ></v-select>
          </v-col>
          <v-col v-if="postDateMethodSelected == 1">
            <v-text-field
              outlined
              label="Post Date"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col v-if="postDateMethodSelected == 2">
            <v-text-field
              outlined
              label="Date"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col v-if="postDateMethodSelected == 2">
            <v-text-field
              outlined
              label="Time"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col v-if="postDateMethodSelected == 3" class="d-flex align-center">
            <span class="d-flex align-center">
              <v-icon color="orange lighten-3">mdi-alert</v-icon>
              <span class="ml-3 d-flex">
                The post date will not be<br>included in the agenda
              </span>
            </span>
          </v-col>
        </v-row>

        <span class="mb-16 d-flex flex-column">
          <v-row class="d-flex mb-4">
            <v-col>
              <span class="d-flex align-center">
                <v-icon color="secondary" size="30">mdi-email</v-icon>
                <span class="text-uppercase darkGrey--text ml-4 font-weight-medium text-body-1">Auto-Notifications</span>
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="pb-0 secondary--text text-uppercase font-weight-medium">
              Board Members
            </v-col>
            <v-col class="d-flex align-center" cols="6">
              <span class="d-flex flex-row align-center">
                <p class="ma-0 mr-6">Include links to</p>
                <v-switch
                  inset
                  label="Agenda"
                  color="secondary"
                  class="mt-0 pa-0 mr-4"
                  hide-details
                ></v-switch>
                <v-switch
                  inset
                  label="Packet"
                  color="secondary"
                  class="mt-0 pa-0 mr-4"
                  hide-details
                ></v-switch>
              </span>
            </v-col>
            <v-col class="d-flex align-center" cols="3">
                <span class="d-flex flex-row align-center">
                  <v-select
                    :items="timeIntervals"
                    item-text="text"
                    item-value="value"
                    label="Interval"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </span>
            </v-col>
          </v-row>
          <v-row>
            <v-divider class="custom-divider"></v-divider>
          </v-row>
          <v-row>
            <v-col class="pb-0 secondary--text text-uppercase font-weight-medium" cols="3">
              Staff
            </v-col>
            <v-col class="d-flex align-center" cols="6">
              <span class="d-flex flex-row align-center">
                <p class="ma-0 mr-6">Include links to</p>
                <v-switch
                  inset
                  label="Agenda"
                  color="secondary"
                  class="mt-0 pa-0 mr-4"
                  hide-details
                ></v-switch>
                <v-switch
                  inset
                  label="Packet"
                  color="secondary"
                  class="mt-0 pa-0 mr-4"
                  hide-details
                ></v-switch>
              </span>
            </v-col>
            <v-col class="d-flex align-center" cols="3">
                <span class="d-flex flex-row align-center">
                  <v-select
                    :items="timeIntervals"
                    item-text="text"
                    item-value="value"
                    label="Interval"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </span>
            </v-col>
          </v-row>
          <v-row>
            <v-divider class="custom-divider"></v-divider>
          </v-row>
          <v-row>
            <v-col class="pb-0 secondary--text text-uppercase font-weight-medium" cols="3">
              Public
            </v-col>
            <v-col class="d-flex align-center" cols="6">
              <span class="d-flex flex-row align-center">
                <p class="ma-0 mr-6">Include links to</p>
                <v-switch
                  inset
                  label="Agenda"
                  color="secondary"
                  class="mt-0 pa-0 mr-4"
                  hide-details
                ></v-switch>
                <v-switch
                  inset
                  label="Packet"
                  color="secondary"
                  class="mt-0 pa-0 mr-4"
                  hide-details
                ></v-switch>
              </span>
            </v-col>
            <v-col class="d-flex align-center" cols="3">
                <span class="d-flex flex-row align-center">
                  <v-select
                    :items="timeIntervals"
                    item-text="text"
                    item-value="value"
                    label="Interval"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </span>
            </v-col>
          </v-row>
          <v-row>
            <v-divider class="custom-divider"></v-divider>
          </v-row>
          <v-row>
            <v-col class="pb-0 secondary--text text-uppercase font-weight-medium" cols="3">
              Media
            </v-col>
            <v-col class="d-flex align-center" cols="6">
              <span class="d-flex flex-row align-center">
                <p class="ma-0 mr-6">Include links to</p>
                <v-switch
                  inset
                  label="Agenda"
                  color="secondary"
                  class="mt-0 pa-0 mr-4"
                  hide-details
                ></v-switch>
                <v-switch
                  inset
                  label="Packet"
                  color="secondary"
                  class="mt-0 pa-0 mr-4"
                  hide-details
                ></v-switch>
              </span>
            </v-col>
            <v-col class="d-flex align-center" cols="3">
                <span class="d-flex flex-row align-center">
                  <v-select
                    :items="timeIntervals"
                    item-text="text"
                    item-value="value"
                    label="Interval"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </span>
            </v-col>
          </v-row>
        </span>

<!--        <v-row class="d-flex mb-4">-->
<!--          <v-col>-->
<!--              <span class="d-flex align-center">-->
<!--                <v-icon color="secondary" size="30">mdi-share-variant</v-icon>-->
<!--                <span class="text-uppercase darkGrey&#45;&#45;text ml-4 font-weight-medium text-body-1">Social Media</span>-->
<!--              </span>-->
<!--          </v-col>-->
<!--        </v-row>-->

        <v-row>
          <v-col>

            <v-tabs
              v-model="tab"
              centered
              color="secondary"
              background-color="transparent"
              class="custom-tab"
              height="70"
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                key="tab-99"
                disabled
                class="opacity-full pl-0"
              >
                <span class="d-flex align-center">
                  <v-icon color="secondary" size="30">mdi-share-variant</v-icon>
                  <span class="text-uppercase darkGrey--text ml-4 font-weight-medium text-body-1">Social Media</span>
                </span>
              </v-tab>
              <v-spacer></v-spacer>
              <v-tab
                v-for="(item, n) in socialMedia"
                :key="'tab-'+n"
              >
                <v-img
                  contain
                  width="30"
                  height="30"
                  :src="item.active === true? require('@/assets/social-' + item.image + '.svg') : require('@/assets/social-' + item.image + '-off.svg')"
                ></v-img>
              </v-tab>
            </v-tabs>

            <v-tabs-items
              v-model="tab"
              class="transparent"
            >
              <v-tab-item
                :key="'tab-99'"
              >
                &nbsp;
              </v-tab-item>
              <v-tab-item
                class="transparent"
                v-for="(item,n) in socialMedia"
                :key="'tab-'+n"
              >
                <v-card elevation="0">
                  <v-card-text>

                    <v-row>
                      <v-col class="d-flex mb-4">
                        <v-switch
                          color="secondary"
                          inset
                          :label="`Use ${ item.name }`"
                          v-model="item.active"
                          class="pt-0 mt-0"
                          hide-details
                        ></v-switch>
                      </v-col>
                    </v-row>

                    <span v-if="item.active">

                      <v-row>
                        <v-col cols="6">
                          <v-select
                            v-model="beSentSelected"
                            :items="beSent"
                            item-text="label"
                            label="To be sent"
                            outlined
                            hide-details
                          ></v-select>
                        </v-col>
                        <v-col cols="3" v-if="beSentSelected == 2">
                          <v-select
                            :items="timeIntervals"
                            label="Choose an interval"
                            outlined
                            hide-details
                          ></v-select>
                        </v-col>
                        <v-col v-if="beSentSelected == 3">
                          <v-text-field
                            outlined
                            label="Date"
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="beSentSelected == 3">
                          <v-menu ref="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                label="Time"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              format="ampm"
                              full-width
                            ></v-time-picker>
                          </v-menu>
                        </v-col>
                        <v-col v-if="beSentSelected === 1" class="d-flex align-center">
                          <v-icon color="orange lighten-2">mdi-alert</v-icon>
                          <span class="d-flex ml-4">
                            The post date will be set automatically<br>when the meeting is finalized
                          </span>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="9" v-if="includeLink">
                          <v-select
                            :items="includeLinks"
                            label="Include links to"
                            outlined
                            hide-details
                          ></v-select>
                        </v-col>
                        <v-col cols="3" class="d-flex align-center">
                          <v-switch
                            color="secondary"
                            inset
                            label="Include link"
                            v-model="includeLink"
                            class="pt-0 mt-0"
                            hide-details
                          ></v-switch>
                        </v-col>
                      </v-row>

                      <v-row>
                         <v-col v-if="item.post">
                          <v-textarea
                            outlined
                            label="Post text"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="3">
                          <v-switch
                            color="secondary"
                            inset
                            label="Post text"
                            v-model="item.post"
                            class="pt-0 mt-0"
                            hide-details
                          ></v-switch>
                        </v-col>
                      </v-row>

                    </span>

                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Cancel</v-btn>
        <v-btn depressed width="130" color="secondary">Add</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'MeetingCreate',
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      labelDisplay: false,
      tab: 1,
      socialMedia: [
        { name: 'Facebook', image: 'facebook', active: false, post: false },
        { name: 'Nextdoor', image: 'nextdoor', active: false, post: false },
        { name: 'Twitter', image: 'twitter', active: false, post: false },
        { name: 'Reddit', image: 'reddit', active: false, post: false },
        { name: 'Instagram', image: 'instagram', active: false, post: false }
      ],
      timeIntervals: [
        { text: 'No Notification', value: '-1' },
        { text: 'Immediately', value: '0' },
        { text: '30 min', value: '30' },
        { text: '1 hour', value: '60' },
        { text: '3 hours', value: '180' }
      ],
      agendaStyles: [
        'Agenda Style 1',
        'Agenda Style 2',
        'Agenda Style 3',
        'Agenda Style 4'
      ],
      postDateMethods: [
        { value: 1, label: 'Manually enter the exact text you want to be used as the post date' },
        { value: 2, label: 'Schedule a specific date and time to use as the post date' },
        { value: 3, label: 'Omit the post date' }
      ],
      postDateMethodSelected: null,
      includeLink: false,
      includeLinks: [
        'Agenda', 'Packet'
      ],
      beSentSelected: 'Immediately upon finalizing',
      beSent: [
        { value: 1, label: 'Immediately upon finalizing' },
        { value: 2, label: 'At a time relative to finalizing' },
        { value: 3, label: 'Schedule a specific date and time to send the post' }
      ]
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
