<template>

  <v-dialog
    width="1150"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Social Media of <span class="font-weight-black">Meeting Name</span></span>
        <span>for <span class="font-weight-black">Board Name</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>

            <v-tabs
              v-model="tab"
              centered
              color="secondary"
              background-color="transparent"
              class="custom-tab"
              height="70"
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                v-for="(item, n) in socialMedia"
                :key="'tab-'+n"
              >
                <v-img
                  contain
                  width="30"
                  height="30"
                  :src="item.active === true? require('@/assets/social-' + item.image + '.svg') : require('@/assets/social-' + item.image + '-off.svg')"
                ></v-img>
              </v-tab>
            </v-tabs>

            <v-tabs-items
              v-model="tab"
              class="transparent"
            >
              <v-tab-item
                class="transparent"
                v-for="(item,n) in socialMedia"
                :key="'tab-'+n"
              >
                <v-card elevation="0">
                  <v-card-text>

                    <v-row>
                      <v-col class="d-flex mb-4">
                        <v-switch
                          color="secondary"
                          inset
                          :label="`Use ${ item.name }`"
                          v-model="item.active"
                          class="pt-0 mt-0"
                          hide-details
                        ></v-switch>
                      </v-col>
                    </v-row>

                    <span v-if="item.active">

                      <v-row>
                        <v-col cols="6">
                          <v-select
                            v-model="beSentSelected"
                            :items="beSent"
                            item-text="label"
                            label="To be sent"
                            outlined
                            hide-details
                          ></v-select>
                        </v-col>
                        <v-col cols="3" v-if="beSentSelected == 2">
                          <v-select
                            :items="timeIntervals"
                            label="Choose an interval"
                            outlined
                            hide-details
                          ></v-select>
                        </v-col>
                        <v-col v-if="beSentSelected == 3">
                          <v-text-field
                            outlined
                            label="Date"
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="beSentSelected == 3">
                          <v-menu ref="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                label="Time"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              format="ampm"
                              full-width
                            ></v-time-picker>
                          </v-menu>
                        </v-col>
                        <v-col v-if="beSentSelected === 1" class="d-flex align-center">
                          <v-icon color="orange lighten-2">mdi-alert</v-icon>
                          <span class="d-flex ml-4">
                            The post date will be set automatically<br>when the meeting is finalized
                          </span>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="9" v-if="includeLink">
                          <v-select
                            :items="includeLinks"
                            label="Include links to"
                            outlined
                            hide-details
                          ></v-select>
                        </v-col>
                        <v-col cols="3" class="d-flex align-center">
                          <v-switch
                            color="secondary"
                            inset
                            label="Include link"
                            v-model="includeLink"
                            class="pt-0 mt-0"
                            hide-details
                          ></v-switch>
                        </v-col>
                      </v-row>

                      <v-row>
                         <v-col v-if="item.post">
                          <v-textarea
                            outlined
                            label="Post text"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="3">
                          <v-switch
                            color="secondary"
                            inset
                            label="Post text"
                            v-model="item.post"
                            class="pt-0 mt-0"
                            hide-details
                          ></v-switch>
                        </v-col>
                      </v-row>

                    </span>

                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'MeetingSocialMedia',
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    tab: 0,
    time: null,
    menu2: false,
    socialMedia: [
      { name: 'Facebook', image: 'facebook', active: false, post: false },
      { name: 'Nextdoor', image: 'nextdoor', active: false, post: false },
      { name: 'Twitter', image: 'twitter', active: false, post: false },
      { name: 'Reddit', image: 'reddit', active: false, post: false },
      { name: 'Instagram', image: 'instagram', active: false, post: false }
    ],
    timeIntervals: ['30 min', '1 h', '3h', 'Immediately'],
    includeLinks: [
      'Agenda', 'Packet'
    ],
    includeLink: false,
    beSentSelected: 'Immediately upon finalizing',
    beSent: [
      { value: 1, label: 'Immediately upon finalizing' },
      { value: 2, label: 'At a time relative to finalizing' },
      { value: 3, label: 'Schedule a specific date and time to send the post' }
    ]
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
