<template>

  <v-dialog
    width="750"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Edit Meeting <span class="font-weight-black">Name of Meeting</span></span>
        <span>for <span class="font-weight-black">Board Name</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>

            <v-row>
              <v-col>
                <v-text-field
                  label="Date"
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col>
                <v-menu ref="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Time"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    format="ampm"
                    full-width
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  label="Location"
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex">
                <v-switch
                  color="secondary"
                  inset
                  label="Use label"
                  v-model="useLabel"
                  class="pt-0 mt-0"
                  hide-details
                ></v-switch>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="darkGrey"
                      size="18"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    Explanation for label
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row v-if="useLabel">
              <v-col>
                <v-text-field
                  label="Label"
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Cancel</v-btn>
        <v-btn depressed width="130" color="secondary">Update</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'MeetingEdit',
  props: {
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    useLabel: false
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
